import { Button, Dialog, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { DataTable, MobileViewDataRow } from '../DataTable';
import { SubHeading } from '../investors/components';
import AddIcon from '@mui/icons-material/Add';
import { Applicant, ControllingPersons } from '../../redux-store/types/api-types';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Formik, useFormikContext, validateYupSchema, yupToFormErrors } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { MFTextField } from '../../lib/formik';
import { DatePicker } from '../../lib/formik/DatePicker';
import {
  controllingPersonsNationalitiesMasters,
  occupationDetailsMasters,
} from '../../utils/constant';
import UseRadioGroup from '../../lib/formik/Radio';
import { ConfirmationDialog } from '../commonComponents';
import { nonIndividualControllingPersons } from '../../utils/schema';
import { formatShortDate } from '../../utils/date';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import { getNationalityList } from '../../redux-store/actions';
import MFSelectField from '../../lib/formik/SelectField';
import { SearchableSelect } from '../../lib/formik/searchSelectField';

const ControllingPersonObject: ControllingPersons = {
  fatcaDetailId: '',
  name: '',
  correspondenceAddress: '',
  countryOfResidense: '',
  tin: '',
  issuingCountry: '',
  controllingPersonType: '',
  indentificationType: '',
  indentificationNumber: '',
  occupationType: '',
  occupation: '',
  dateOfBirth: '',
  countryOfBirth: '',
  nationality: '',
  isActive: true,
};

const headers = [
  {
    header: 'Name',
    label: 'name',
    valueGetter: (row: ControllingPersons) => row.name || 'N/A',
  },
  {
    header: 'TIN',
    valueGetter: (row: ControllingPersons) => row.tin || 'N/A',
  },
  {
    header: 'Issuing Country',
    valueGetter: (row: ControllingPersons) => row.issuingCountry || 'N/A',
  },
];

const readOnlyHeaders = [
  {
    header: 'Name of controlling person',
    label: 'name',
    valueGetter: (row: ControllingPersons) => row.name || 'N/A',
  },
  {
    header: 'Correspondence address',
    valueGetter: (row: ControllingPersons) => row.correspondenceAddress || 'N/A',
  },
  {
    header: 'Country of residence for tax purposes',
    valueGetter: (row: ControllingPersons) => row.countryOfResidense || 'N/A',
  },
  {
    header: 'TIN',
    valueGetter: (row: ControllingPersons) => row.tin || 'N/A',
  },
  {
    header: 'Issuing country',
    valueGetter: (row: ControllingPersons) => row.issuingCountry || 'N/A',
  },
  {
    header: 'Controlling Person Type',
    valueGetter: (row: ControllingPersons) => row.controllingPersonType || 'N/A',
  },
  {
    header: 'Indentification Type',
    valueGetter: (row: ControllingPersons) => row.indentificationType || 'N/A',
  },
  {
    header: 'Indentification Number',
    valueGetter: (row: ControllingPersons) => row.indentificationNumber || 'N/A',
  },
  {
    header: 'Occupation Type',
    valueGetter: (row: ControllingPersons) => row.occupationType || 'N/A',
  },
  {
    header: 'Occupation',
    valueGetter: (row: ControllingPersons) => row.occupation || 'N/A',
  },
  {
    header: 'Date of Birth',
    valueGetter: (row: ControllingPersons) =>
      row.dateOfBirth ? formatShortDate(new Date(row.dateOfBirth)) : 'N/A',
  },
  {
    header: 'Country of Birth',
    valueGetter: (row: ControllingPersons) => row.countryOfBirth || 'N/A',
  },
  {
    header: 'Nationality',
    valueGetter: (row: ControllingPersons) => row.nationality || 'N/A',
  },
];

export default function ControllingPerson({
  applicantsIndex,
  header,
  _applicants,
  questionText,
  disabled,
  nationalityDropdown,
}: {
  applicantsIndex: number;
  header: string;
  _applicants: Partial<Applicant>[];
  questionText: string;
  disabled: boolean;
  nationalityDropdown: mdmsCountriesList[];
}): JSX.Element {
  const [rowIndex, setRowIndex] = useState(Number);
  const [controllingPersonObj, setcontrollingPersonObj] = useState(ControllingPersonObject);
  const [open, setOpen] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [controllingPersonsArr, setControllingPersonsArr] = useState(
    _applicants[applicantsIndex].fatcadetail?.controllingpersons || []
  );
  const fatcaId = _applicants[applicantsIndex].fatcadetail?.id || '';

  const { setFieldValue } = useFormikContext();
  const handleClose = () => {
    setcontrollingPersonObj(ControllingPersonObject);
    setOpen(false);
  };
  const onSubmit = (values: ControllingPersons) => {
    const editControllingPersons = controllingPersonsArr
      .filter((_item) => _item.isActive)
      .map((person, ind) => {
        if (ind === rowIndex) {
          return values;
        }
        return person;
      });
    const deletedControllingPersons = controllingPersonsArr.filter(
      (_item) => !_item.isActive && _item.id
    );
    setControllingPersonsArr(
      controllingPersonObj.tin === ''
        ? [
            ...controllingPersonsArr,
            {
              ...values,
              fatcaDetailId: fatcaId,
              dateOfBirth: values.dateOfBirth === '' ? null : values.dateOfBirth,
            },
          ]
        : [...editControllingPersons, ...deletedControllingPersons]
    );

    setFieldValue(
      '_applicants',
      _applicants.map((applicant, ind) => {
        if (ind === applicantsIndex) {
          return {
            ...applicant,
            fatcadetail: {
              ...applicant.fatcadetail,
              controllingpersons:
                controllingPersonObj.tin === ''
                  ? [
                      ...controllingPersonsArr,
                      {
                        ...values,
                        fatcaDetailId: fatcaId,
                        dateOfBirth: values.dateOfBirth === '' ? null : values.dateOfBirth,
                      },
                    ]
                  : [...editControllingPersons, ...deletedControllingPersons],
            },
          };
        }
        return applicant;
      })
    );
    handleClose();
  };

  const editCell = {
    header: '',
    renderEditDeleteCell: (row: ControllingPersons, index: number): JSX.Element => {
      return (
        <Box display="flex">
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                setcontrollingPersonObj(row), setRowIndex(index), setOpen(true);
              }}
              sx={{
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
              }}>
              <EditIcon sx={{ color: 'primary.main' }} fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              sx={{
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
              }}
              onClick={() => {
                setcontrollingPersonObj(row), setRowIndex(index), setDeletePopup(true);
              }}>
              <DeleteOutlineIcon color="error" fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
  };

  return (
    <>
      <SubHeading sx={{ marginTop: '23px', marginBottom: '20px' }}>{header}</SubHeading>
      <Typography
        sx={{
          fontFamily: 'Work Sans,sans-serif',
          fontWeight: 400,
          fontSize: '16px',
          pt: 1,
          pb: 1,
          width: '100%',
        }}>
        {questionText}
      </Typography>
      {controllingPersonsArr?.filter((_item) => _item.isActive).length !== 0 &&
        (disabled ? (
          <>
            {controllingPersonsArr.map((persons, index) => (
              <MobileViewDataRow
                index={index}
                row={persons}
                key={persons.id}
                tableHeader={readOnlyHeaders}
              />
            ))}
          </>
        ) : (
          <Grid xs={12} sm={12} lg={12}>
            <DataTable
              tableData={controllingPersonsArr.filter((_item) => _item.isActive)}
              tableHeader={[...headers, editCell]}
              renderAdditionalRow={false}
              tableHeaderCustomStyles={{
                '.MuiTableCell-head': {
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'primary.main',
                },
              }}
              rowCustomStyles={{
                '.MuiTableCell-root': {
                  py: '8px',
                  overflowWrap: 'anywhere',
                },
              }}
              boxStyles={{ minHeight: '0px', minWidth: 450 }}
            />
          </Grid>
        ))}
      <Grid
        xs={12}
        sm={12}
        lg={12}
        display="flex"
        sx={{
          justifyContent: {
            xs:
              controllingPersonsArr?.filter((_item) => _item.isActive).length === 0
                ? 'start'
                : 'center',
            sm:
              controllingPersonsArr?.filter((_item) => _item.isActive).length === 0
                ? 'start'
                : 'end',
          },
        }}>
        {!disabled && (
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            sx={{
              color: 'primary.main',
              fontWeight: 500,
              fontSize: 13,
              mr: 2.5,
              whiteSpace: 'nowrap',
              mt: '20px',
            }}
            onClick={() => {
              setcontrollingPersonObj(ControllingPersonObject), setOpen(true);
            }}>
            Add Controlling Person
          </Button>
        )}

        <Dialog
          onClose={handleClose}
          open={open}
          sx={{
            '.MuiPaper-root ': {
              px: 1,
              maxWidth: 900,
            },
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'space-between',
              position: 'sticky',
              top: 0,
              boxShadow: '0 2px #ccc 0.6',
              zIndex: 10,
              bgcolor: 'white',
              py: 3,
            }}>
            <Typography
              sx={{
                color: '#0D2762',
                fontSize: '20px',
                fontWeight: 500,
                ml: { xs: 2, sm: 5 },
              }}>
              {controllingPersonObj.tin ? 'Edit Controlling Person' : 'Add Controlling Person'}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: { xs: '100%', sm: '90%' },
              mx: 'auto',
              px: { xs: 3, sm: 0 },
              pb: 4,
            }}>
            <Formik
              initialValues={controllingPersonObj}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validate={(values: ControllingPersons) => {
                const setValuesForEdit = controllingPersonsArr
                  .filter((_item) => _item.isActive)
                  .map((person, index) => {
                    if (index === rowIndex) {
                      if (
                        values.tin !== person.tin &&
                        values.indentificationNumber !== person.indentificationNumber
                      ) {
                        return person;
                      }
                      if (
                        values.tin === person.tin &&
                        values.indentificationNumber === person.indentificationNumber
                      ) {
                        // eslint-disable-next-line
                        const { tin, indentificationNumber, ...rest } = person;
                        return { ...rest };
                      }
                      if (
                        values.tin !== person.tin &&
                        values.indentificationNumber === person.indentificationNumber
                      ) {
                        // eslint-disable-next-line
                        const { indentificationNumber, ...rest } = person;
                        return { ...rest };
                      }
                      if (
                        values.tin === person.tin &&
                        values.indentificationNumber !== person.indentificationNumber
                      ) {
                        // eslint-disable-next-line
                        const { tin, ...rest } = person;
                        return { ...rest };
                      }
                      return;
                    }
                    return person;
                  });
                try {
                  validateYupSchema(
                    values,
                    nonIndividualControllingPersons,
                    true,
                    controllingPersonObj.tin
                      ? setValuesForEdit
                      : controllingPersonsArr.filter((_item) => _item.isActive)
                  );
                } catch (e) {
                  return yupToFormErrors(e);
                }
              }}>
              {({ handleSubmit }) => (
                <Grid
                  container
                  rowSpacing={1}
                  //columnSpacing={5}
                  sx={{
                    width: '100%',
                    ml: 0,
                    '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
                    mt: 1,
                  }}
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}>
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`name`}
                      label="Name of controlling person *"
                      placeholder="Enter Name of controlling person"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`correspondenceAddress`}
                      label="Correspondence address *"
                      placeholder="Enter Correspondence address"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <MFTextField
                      name={`countryOfResidense`}
                      label="Country of residence for tax purposes *"
                      placeholder="Enter Country of residence for tax purposes"
                    /> */}
                    <SearchableSelect
                      name={`countryOfResidense`}
                      label="Country of residence for tax purposes *"
                      items={nationalityDropdown.map((nationality) => ({
                        key: nationality.name,
                        value: nationality.name,
                      }))}
                      searchFieldPlaceholder={'Search Country'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MFTextField name={`tin`} label="TIN *" placeholder="Enter TIN" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <MFTextField
                      name={`issuingCountry`}
                      label="Issuing country *"
                      placeholder="Enter issuing country"
                    /> */}
                    <SearchableSelect
                      name={`issuingCountry`}
                      label="Issuing country *"
                      items={nationalityDropdown.map((nationality) => ({
                        key: nationality.name,
                        value: nationality.name,
                      }))}
                      searchFieldPlaceholder={'Search Country'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`controllingPersonType`}
                      label="Controlling Person Type *"
                      placeholder="Enter Controlling Person Type"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`indentificationType`}
                      label="Indentification Type *"
                      placeholder="Enter Indentification Type"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`indentificationNumber`}
                      label="Indentification Number *"
                      placeholder="Enter Indentification Number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`occupationType`}
                      label="Occupation Type *"
                      placeholder="Enter Occupation Type"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <MFTextField
                      name={`occupation`}
                      label="Occupation *"
                      placeholder="Enter Occupation"
                    /> */}
                    <MFSelectField
                      name={`occupation`}
                      label="Occupation *"
                      items={occupationDetailsMasters.map((occupation) => ({
                        key: occupation,
                        value: occupation,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label={'Date of Birth *'}
                      inputLabelStyles={{
                        transform: 'unset',
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'rgba(0,0,0,0.7)',
                      }}
                      placeholder={'DD/MM/YYYY'}
                      name={`dateOfBirth`}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <MFTextField
                      name={`countryOfBirth`}
                      label="Country Of Birth *"
                      placeholder="Enter Country Of Birth"
                    /> */}
                    <SearchableSelect
                      name={`countryOfBirth`}
                      label="Country Of Birth *"
                      items={nationalityDropdown.map((nationality) => ({
                        key: nationality.name,
                        value: nationality.name,
                      }))}
                      searchFieldPlaceholder={'Search Country'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
                    {/* <UseRadioGroup
                      name={'nationality'}
                      formLabel="Nationality *"
                      items={controllingPersonsNationalitiesMasters.map((nationality) => ({
                        label: nationality,
                        value: nationality,
                      }))}
                    /> */}
                    <SearchableSelect
                      name={`nationality`}
                      label="Nationality *"
                      items={nationalityDropdown.map((nationality) => ({
                        key: nationality.nationality,
                        value: nationality.nationality,
                      }))}
                      searchFieldPlaceholder={'Search Nationality'}
                    />
                  </Grid>

                  <Grid xs={12} sm={12} lg={12} display="flex" sx={{ justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: 15,
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 500,
                        alignSelf: 'center',
                        mt: 2,
                      }}
                      type="submit">
                      {controllingPersonObj.tin ? 'Save' : 'Add'}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </Box>
        </Dialog>
        <ConfirmationDialog
          message={'Are you sure you want to delete Controlling Person ?'}
          open={deletePopup}
          setOpen={() => setDeletePopup(false)}
          onSave={() => {
            setDeletePopup(false);
            const deleteControllingPersons = controllingPersonsArr
              .filter((_item) => _item.isActive)
              .map((controllingPerson, _ind) => {
                if (rowIndex === _ind) {
                  return { ...controllingPerson, isActive: false };
                }
                return controllingPerson;
              });
            const deletedControllingPersons = controllingPersonsArr.filter(
              (_item) => !_item.isActive && _item.id
            );
            const active_DeletedControllingPersons = deleteControllingPersons.filter(
              (_item) => _item.isActive || (!_item.isActive && _item.id)
            );
            setControllingPersonsArr([
              ...active_DeletedControllingPersons,
              ...deletedControllingPersons,
            ]);
            setFieldValue(
              '_applicants',
              _applicants.map((applicant, ind) => {
                if (ind === applicantsIndex) {
                  return {
                    ...applicant,
                    fatcadetail: {
                      ...applicant.fatcadetail,
                      controllingpersons: [
                        ...active_DeletedControllingPersons,
                        ...deletedControllingPersons,
                      ],
                    },
                  };
                }
                return applicant;
              })
            );
            setcontrollingPersonObj(ControllingPersonObject);
            setRowIndex(Number);
          }}
          onCancel={() => {
            setcontrollingPersonObj(ControllingPersonObject);
            setRowIndex(Number);
            setDeletePopup(false);
          }}
        />
      </Grid>
    </>
  );
}
