import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles } from './index';
import { currencyConversion } from '../../utils/utilityFunctions';
import { CurrencyMaster, YES_NO_BOOLEAN_MAPPING } from '../../utils/constant';
import ReadOnlyView from './ReadOnlyView';
import { styles } from '../../Styles/styles';

export default function InvestAndPaymentDetails({
  application,
}: {
  application: Partial<ApplicationProps>;
}): JSX.Element {
  const localeFormat = CurrencyMaster[application?.plan?.currency?.toLowerCase() || '']?.localeCode;
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Investment Details
            </Typography>
            <Gridstyles sx={{ width: '100%' }}>
              <Grid
                item
                container
                pt={2}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue
                  title={'Group Investment'}
                  description={
                    YES_NO_BOOLEAN_MAPPING[application?.groupInvestment?.toString() || '']
                  }
                />
                <KeyValue title={'Fund (Scheme)'} description={application.scheme?.schemeName} />
                <KeyValue title={'Class Plan'} description={application.plan?.planDescription} />
                <KeyValue
                  title={'Commitment Amount'}
                  description={application.commitmentAmount?.toLocaleString(localeFormat)}
                  amount={currencyConversion(
                    application.commitmentAmount,
                    application.plan?.currency
                  )}
                />
                <KeyValue
                  title={'Management Fees Rate (%)'}
                  description={application.managementFeesRate?.toString()}
                />
                {/* <KeyValue title={'Tax Rate'} description={application.taxRate} /> */}
                {/* <KeyValue title={'DrawDown Number'} description={application.drawDownNumber} /> */}
                <KeyValue
                  title={'Initial Drawdown Amount'}
                  description={application.contributionChequeAmount?.toLocaleString(localeFormat)}
                  amount={currencyConversion(
                    application.contributionChequeAmount,
                    application.plan?.currency
                  )}
                />
                {/* <KeyValue
                  title={'Setup Fee Percentage'}
                  description={application.setupFeePercentage}
                />
                <KeyValue
                  title={'Setup Fee Amount'}
                  description={application.setupFeeAmount}
                  amount={currencyConversion(
                    application.setupFeeAmount,
                    application.plan?.currency
                  )}
                />
                <KeyValue
                  title={'GST (GST On Setup Fee Amount)'}
                  description={application.setupFeeGST}
                  amount={currencyConversion(application.setupFeeGST, application.plan?.currency)}
                />
                <KeyValue
                  title={'Total Setup Fee'}
                  description={application.totalSetupFee}
                  amount={currencyConversion(application.totalSetupFee, application.plan?.currency)}
                /> */}
                {/* <KeyValue
                  title={'TDS'}
                  description={application.setupFeeTDS}
                  amount={currencyConversion(application.setupFeeTDS, application.plan?.currency)}
                />
                <KeyValue
                  title={'Setup Fee Payable'}
                  description={application.netSetupFeeLessTDS}
                  amount={currencyConversion(
                    application.netSetupFeeLessTDS,
                    application.plan?.currency
                  )}
                /> */}
                {/* <KeyValue
                  title={'Total Amount Payable'}
                  description={application.totalAmountPayable}
                  amount={currencyConversion(
                    application.totalAmountPayable,
                    application.plan?.currency
                  )}
                /> */}
                {/* <KeyValue
                  title={'Operating Expense Percentage'}
                  description={application.operatingExpensePercentage}
                />
                <KeyValue title={'Operating Expense'} description={application.operatingExpense} /> */}
                {application?.applicationOperatingExpenses && (
                  <Grid item xs={12}>
                    <ReadOnlyView
                      label={'Operating Expenses'}
                      value={application?.applicationOperatingExpenses}
                    />
                  </Grid>
                )}
                {application?.applicationSetupFee && (
                  <Grid item xs={12}>
                    <ReadOnlyView label={'Setup Fee'} value={application?.applicationSetupFee} />
                  </Grid>
                )}
                {/* {application?.applicationAdditionalReturn && (
                  <Grid item xs={12}>
                    <ReadOnlyView
                      label={'Additional Return'}
                      value={application?.applicationAdditionalReturn}
                    />
                  </Grid>
                )} */}
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}>
          {/* <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Payment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue title={'Payment Bank Name'} description={application.paymentBankame} />
                <KeyValue
                  title={'Payment Bank Account Number'}
                  description={application.paymentBankAccountNumber}
                />
                <KeyValue
                  title={'Payment Bank Account Type'}
                  description={application.paymentBankAccontType}
                />
                <KeyValue title={'Mode Of Payment'} description={application.modeOfPayment} />
                <KeyValue
                  title={'Payment/Cheque Date'}
                  description={formatShortDate(application.chequeDate)}
                />
                <KeyValue title={'Payment Ref No'} description={application.paymentRefNo} />
              </Grid>
            </Gridstyles>
          </Grid> */}
        </Box>
      </Box>
    </>
  );
}
