import { Typography } from '@mui/material';
import React from 'react';
import { Item } from '../Investments/investor-application';

export default function ReadOnlyView({
  label,
  value,
}: {
  label?: string;
  value?: string;
}): JSX.Element {
  return (
    <>
      {value && (
        <>
          <Item sx={{ fontSize: 15, fontWeight: 500, color: 'rgba(0,0,0,0.7)', mb: 1 }}>
            {label}
          </Item>
          <Typography sx={{ color: 'text.secondary' }}>{value}</Typography>
        </>
      )}
    </>
  );
}
