import { Box, Grid, LinearProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { Formik, useFormikContext } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import {
  AddAuthorisedSignatoryRequestBody,
  AuthorisedSignatories,
} from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import {
  addAuthorisedSignatory,
  updateAuthorisedSignatory,
  getAuthorisedSignatoryById,
} from '../../redux-store/actions/userManagement';
import { AuthorisedSignatoryAndPOASchema } from '../../utils/schema';
import { Location } from 'history';
import { useEffect, useState } from 'react';
import { CountryCodesDropDown } from '../commonComponents';
import { AddressFieldEditable } from './AddressFields';
import { styles } from '../../Styles/styles';
import { BackButtonAndHeader } from './userBackButton';

export const CommonFormForCompanyDistributors = (props: any): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField name="name" label="Name *" placeholder="Enter Name" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField name="email" label="Email Id *" placeholder="Enter Email Id" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField
            name="phone"
            label="Mobile Number *"
            placeholder="Enter Mobile Number"
            // startAdornment={
            //   <CountryCodesDropDown
            //     name={`countryNameAndCode`}
            //     value={props.values.countryNameAndCode}
            //   />
            // }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField
            name="panNumber"
            label="Pan"
            placeholder="Enter Pan"
            inputProps={{ style: { textTransform: 'uppercase' } }}
            onChange={(e) => {
              setFieldValue('panNumber', e.target.value.toUpperCase());
            }}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4}>
          <MFTextField
            name="distributorId"
            label="Distributor Id *"
            placeholder="Enter Distributor Id"
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={4}>
          <MFTextField name="amcId" label="AmcId" placeholder="Enter AmcId" />
        </Grid> */}

        <AddressFieldEditable />
      </Grid>
      <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
        <MFSubmitButton label="Save" />
      </Box>
    </>
  );
};

export default function AddAuthorisedSignatory({
  location,
}: {
  location: Location<{ authorisedSignatoryId: number; distributorId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authorisedSignatoryId, distributorId: ID } = location.state || {};
  const [formikValues, setFormikValues] = useState<AddAuthorisedSignatoryRequestBody | null>();

  const initialValues: AddAuthorisedSignatoryRequestBody = {
    name: '',
    email: '',
    countryCode: '+91',
    countryNameAndCode: 'India: +91',
    phone: '',
    panNumber: '',
    pincode: '',
    buildingNo: '',
    streetName: '',
    city: '',
    state: '',
    country: '',
    distributorId: ID,
    amcId: '',
    isActive: true,
  };

  const [authorisedSignatoryDetails, setAuthorisedSignatoryDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   let isComponentActive = true;
  //   (async function () {
  //     try {
  //       if (!formikValues) return;
  //       if (authorisedsignatory) {
  //         await dispatch(updateAuthorisedSignatory(id, formikValues));
  //       } else {
  //         await dispatch(addAuthorisedSignatory({ ...formikValues, distributorId: ID }));
  //       }

  //       // const res = (await dispatch(
  //       //   getDistributorsList({ type: 'company' })
  //       // )) as unknown as DistributorListType[];

  //       // const ab = res.filter((a) => {
  //       //   return a.id === Number(distributorId);
  //       // });
  //       if (!isComponentActive) return;
  //       history.push('authorised-signatory', { distributorId: ID });
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  //   return () => {
  //     isComponentActive = false;
  //   };
  // }, [formikValues]);
  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        if (authorisedSignatoryId) {
          const getAuthorisedSignatoryDetails = (await dispatch(
            getAuthorisedSignatoryById(authorisedSignatoryId)
          )) as unknown as AuthorisedSignatories;
          const {
            panNumber,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            distributorId,
            amcId,
            id,
            isActive,
          } = getAuthorisedSignatoryDetails;
          const {
            firstName: name,
            phone,
            email,
            countryCode,
            countryNameAndCode,
          } = getAuthorisedSignatoryDetails.user;
          setAuthorisedSignatoryDetails({
            ...authorisedSignatoryDetails,
            name,
            phone,
            email,
            panNumber,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            distributorId,
            amcId,
            id,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: AddAuthorisedSignatoryRequestBody) => {
    try {
      if (authorisedSignatoryId) {
        await dispatch(
          updateAuthorisedSignatory(authorisedSignatoryId, {
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        );
        history.push('authorised-signatory-details', {
          authorisedSignatoryId,
          distributorId: values.distributorId,
        });
      } else {
        await dispatch(
          addAuthorisedSignatory({
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
            distributorId: ID,
          })
        );
        history.push('authorised-signatory', { distributorId: values.distributorId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  return (
    <>
      <Formik
        initialValues={authorisedSignatoryDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={AuthorisedSignatoryAndPOASchema}>
        {({ handleSubmit, values }) => (
          <Box sx={styles.UsersFormikBox} component="form" noValidate onSubmit={handleSubmit}>
            <BackButtonAndHeader
              User={
                authorisedSignatoryId
                  ? 'Authorised Signatories'
                  : 'Onboarding Authorised Signatories'
              }
              onClickBack={() =>
                authorisedSignatoryId
                  ? history.push('authorised-signatory-details', {
                      authorisedSignatoryId: values.id,
                      distributorId: values.distributorId,
                    })
                  : history.push('authorised-signatory', {
                      distributorId: values.distributorId,
                    })
              }
            />

            {loading ? <LinearProgress /> : <CommonFormForCompanyDistributors values={values} />}
          </Box>
        )}
      </Formik>
    </>
  );
}
