import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { ProceedSaveLater } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { MFTextField } from '../../lib/formik';
import {
  ApplicationProps,
  JointholderRelationMaster,
  DistributorListType,
} from '../../redux-store/types/api-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { getFunds } from '../../redux-store/actions/funds';
import { ClassPlanProps, FundProps } from '../../redux-store/types/funds';
import { investmentPaymentDetailsSchema } from '../../utils/schema';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APPLICATION_TYPE,
  USER_ROLES,
  defaultCurrency,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  currencyConversion,
  saveForLater,
  statusRoleBased,
  updatedCommitmentAmount,
} from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import { getDirectApplicableFunds } from '../../redux-store/actions/funds';
import { getDistributorById } from '../../redux-store/actions/userManagement';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { jointHolderRelationsList } from '../../redux-store/actions';
import MFCheckbox from '../../lib/formik/Checkbox';
import { ConfirmationDialog } from '../commonComponents';
import ReadOnlyView from '../ApplicationDetails/ReadOnlyView';
import AmountTextField from '../../lib/formik/AmountTextField';

type Values = Partial<ApplicationProps> & {
  saveType: string;
};

const fundInitialValues = {
  schemeId: '',
  planId: '',
  commitmentAmount: 0,
  setupFeePercentage: 0,
  managementFeesRate: 0,
  taxRate: 0,
  drawDownNumber: 0,
  contributionChequeAmount: 0,
  setupFeeAmount: null,
  setupFeeGST: null,
  totalSetupFee: null,
  setupFeeTDS: null,
  netSetupFeeLessTDS: null,
  totalAmountPayable: null,
  groupInvestment: null,
};

const initialValues: Values = {
  ...fundInitialValues,
  modeOfPayment: 'cheque',
  paymentRefNo: '',
  chequeDate: '',
  paymentBankAccountNumber: '',
  paymentBankame: '',
  paymentBankAccontType: '',
  operatingExpense: null,
  operatingExpensePercentage: null,
  applicationSetupFee: '',
  applicationOperatingExpenses: '',
  applicationAdditionalReturn: '',
  saveType: 'save and proceed',
};

export default function InvestmentPaymentDetails(): JSX.Element {
  // const [open, setOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [fundsList, setFundsList] = useState<FundProps[]>([]);
  const [selectedFund, setSelectedFund] = useState<FundProps | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Partial<ClassPlanProps> | null>(null);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [paymentDetails, setPaymentDetails] = useState(initialValues);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [jointHoldersData, setJointHoldersData] = useState<JointholderRelationMaster>();
  const [openDialog, setDialog] = useState<{
    investmentData: Values;
    checkApplication: boolean;
  } | null>(null);

  const {
    minCommitmentAmount = 10000000,
    maxCommitmentAmount = 20000000,
    minContributionPercentage = 10,
    currency = defaultCurrency,
  } = selectedPlan || {};

  useEffect(() => {
    const { plan } = application || ({} as ApplicationProps);
    const {
      minCommitmentAmount = 1,
      maxCommitmentAmount = 0,
      minContributionPercentage = 1,
      currency = defaultCurrency,
    } = plan || {};
    setSelectedPlan(
      plan
        ? {
            ...selectedPlan,
            minCommitmentAmount: Number(minCommitmentAmount),
            maxCommitmentAmount: Number(maxCommitmentAmount),
            minContributionPercentage: Number(minContributionPercentage),
            currency,
          }
        : null
    );

    setPaymentDetails({
      ...paymentDetails,
      schemeId: application?.schemeId || '',
      planId: application?.planId || '',
      commitmentAmount: application?.commitmentAmount || 0,
      setupFeePercentage: application?.setupFeePercentage || 0,
      managementFeesRate: application?.managementFeesRate || 0,
      // taxRate: application?.taxRate || null,
      // drawDownNumber: application?.drawDownNumber || null,
      contributionChequeAmount: Number(application?.contributionChequeAmount) || 0,
      modeOfPayment: application?.modeOfPayment || '',
      paymentRefNo: application?.paymentRefNo || '',
      chequeDate: application?.chequeDate || null,
      paymentBankAccountNumber: application?.paymentBankAccountNumber || '',
      paymentBankame: application?.paymentBankame || '',
      paymentBankAccontType: application?.paymentBankAccontType || '',
      setupFeeAmount: application?.setupFeeAmount || 0,
      setupFeeGST: application?.setupFeeGST || 0,
      totalSetupFee: application?.totalSetupFee || 0,
      setupFeeTDS: 0,
      netSetupFeeLessTDS: 0,
      totalAmountPayable: 0,
      groupInvestment: application?.groupInvestment || false,
      operatingExpense: application?.operatingExpense || 0,
      operatingExpensePercentage: application?.operatingExpensePercentage || 0,
      applicationOperatingExpenses: application?.applicationOperatingExpenses || '',
      applicationSetupFee: application?.applicationSetupFee || '',
      applicationAdditionalReturn: application?.applicationAdditionalReturn || '',
    });
  }, [application]);

  const submitInvestmentData = async (values: Values, checkApplication: boolean) => {
    const {
      id,
      applicant1ReferenceId = '',
      applicationType,
      status,
      hasPOA,
      applicationNumber,
    } = application || {};
    const { saveType, ...rest } = values;
    const isSaveLater = saveType !== 'save and proceed';
    if (id && !checkApplication) {
      await dispatch(
        updateApplication({
          body: {
            ...application,
            ...rest,
            contributionChequeAmount: Number(rest.contributionChequeAmount) || 0,
            setupFeePercentage: Number(rest.setupFeePercentage),
            status: statusRoleBased(role, application),
            currentStep: applicationType === APPLICATION_TYPE.NON_INDIVIDUAL ? 4 : 8,
            // applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            //   ? !!currentStep && currentStep > 3
            //     ? currentStep
            //     : Number(currentStep) + 1
            //   : !!currentStep && currentStep > 7
            //   ? currentStep
            //   : Number(currentStep) + 1,
          },
          applicationId: id,
          ...(isSaveLater && { toastMessage: '' }),
        })
      );
      !isSaveLater
        ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
          ? history.push('bank-details', { id, applicant1ReferenceId })
          : history.push('document-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
    } else if (checkApplication) {
      if (isSaveLater) {
        enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      !isSaveLater
        ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
          ? history.push('bank-details', { id, applicant1ReferenceId })
          : history.push('document-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      const { currentStep, applicationType } = application || {};
      const { saveType, ...rest } = values;
      const checkApplication = applicationComparison(
        { ...application, setupFeePercentage: Number(application?.setupFeePercentage) },
        {
          ...application,
          ...rest,
          contributionChequeAmount: Number(rest.contributionChequeAmount) || 0,
          setupFeePercentage: Number(rest.setupFeePercentage),
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > 3
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep && currentStep > 7
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      if (values?.groupInvestment && !checkApplication) {
        setDialog({ investmentData: values, checkApplication });
      } else {
        setLoading(true);
        await submitInvestmentData(values, checkApplication);
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = application?.distributor_id
          ? ((
              (await dispatch(
                getDistributorById(application?.distributor_id)
              )) as unknown as DistributorListType
            ).planDetails.schemes as FundProps[])
          : ((await dispatch(
              getDirectApplicableFunds({ isActive: true })
            )) as unknown as FundProps[]);
        if (!isComponentAlive) {
          return;
        }
        const { scheme } = application || {};
        const updatedFundPlan =
          application?.schemeId &&
          (!application.scheme.isActive ||
            !(response.map((res) => res.id?.toString()) as unknown as string[]).includes(
              application?.schemeId?.toString()
            ))
            ? [
                ...response,
                {
                  ...application.scheme,
                  plans: [application.plan],
                } as unknown as FundProps,
              ]
            : response;
        setFundsList(updatedFundPlan);
        const updatePlans =
          application?.schemeId &&
          application?.scheme.isActive &&
          application?.planId &&
          !application.plan?.isActive
            ? ({
                ...updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)),
                plans: [
                  ...(
                    updatedFundPlan.find(
                      (fund) => Number(fund.id) === Number(scheme?.id)
                    ) as unknown as FundProps
                  ).plans,
                  application.plan,
                ],
              } as unknown as FundProps)
            : updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)) || null;
        setSelectedFund(updatePlans);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const getClassPlanOptionsOrDetails = (detail?: string) => {
    if (detail) {
      return selectedFund?.[detail] || '';
    }
    return (
      selectedFund?.plans?.map((plan) => ({ key: plan.planDescription, value: Number(plan.id) })) ||
      []
    );
  };

  useEffect(() => {
    (async function () {
      try {
        const jointHodersMdmsMasters = (await dispatch(
          jointHolderRelationsList()
        )) as unknown as JointholderRelationMaster;
        setJointHoldersData(jointHodersMdmsMasters);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <>
      <Formik
        initialValues={paymentDetails}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validate={(values: Values) => {
          const minCommitmentAmountBasedOnJointHolders =
            application && application.applicants?.length > 1 && jointHoldersData
              ? (updatedCommitmentAmount(
                  Number(minCommitmentAmount),
                  application.applicants,
                  jointHoldersData
                ) as number)
              : minCommitmentAmount;
          try {
            validateYupSchema(
              values,
              investmentPaymentDetailsSchema(
                minCommitmentAmountBasedOnJointHolders,
                maxCommitmentAmount,
                minContributionPercentage,
                selectedPlan?.currency || ''
              ),
              true,
              values
            );
          } catch (e) {
            return yupToFormErrors(e);
          }
        }}>
        {({ handleSubmit, values, setValues }) => (
          <Grid
            container
            rowSpacing={1}
            columnSpacing={5}
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}>
            <Grid item xs={12}>
              <MFCheckbox
                name={`groupInvestment`}
                label="Group Investment"
                sx={{ mt: 1 }}
                disabled={!!application?.distributor_id}
              />
            </Grid>
            <Grid item xs={12}>
              <SearchableSelect
                name={'schemeId'}
                label="Fund (Scheme) *"
                items={fundsList.map((fund) => ({ value: Number(fund.id), key: fund.schemeName }))}
                onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                  setValues({
                    ...values,
                    ...fundInitialValues,
                    schemeId: value as string,
                    setupFeeAmount: 0,
                    setupFeeGST: 0,
                    totalSetupFee: 0,
                    setupFeeTDS: 0,
                    netSetupFeeLessTDS: 0,
                    totalAmountPayable: 0,
                    groupInvestment: values.groupInvestment,
                    applicationOperatingExpenses: '',
                    applicationSetupFee: '',
                    applicationAdditionalReturn: '',
                  });
                  setSelectedFund(fundsList.find((f) => Number(f.id) === value) || null);
                  setSelectedPlan(null);
                }}
                disabled={isFieldDisabled}
                searchFieldPlaceholder={'Search Fund (Scheme)'}
              />
            </Grid>
            {/* {!!values.schemeId && (
            <Grid item xs={12}>
              <Box
                sx={{
                  boxShadow: '0px 0px 12px 2px rgba(0, 0, 0, 0.1)',
                  borderRadius: '10px',
                  p: { xs: '20px', sm: '30px 40px' },
                  my: 2,
                }}>
                <Box
                  onClick={() => setOpen(!open)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: 600,
                      fontSize: 16,
                      color: 'primary.main',
                      letterSpacing: '0.05em',
                    }}>
                    Bank Details related to this fund (Scheme)
                  </Typography>
                  <ExpandMoreIcon sx={{ transform: open ? 'rotate(180deg)' : '' }} />
                </Box>
                {open && (
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      mt: 3,
                      '.MuiTypography-root': {
                        fontSize: 16,
                        letterSpacing: '0.05em',
                        '&.MuiTypography-body2 ': { fontWeight: 600 },
                      },
                    }}>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Name</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankName')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Account Number</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankAccountNumber')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>IFSC Code</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('ifscCode')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>Bank Branch</Typography>
                      <Typography variant="body2">
                        {getClassPlanOptionsOrDetails('bankBranch')}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          )} */}
            <Grid item xs={12} sm={6}>
              <SearchableSelect
                name={'planId'}
                label="Class (Plan) *"
                items={getClassPlanOptionsOrDetails() as [{ key: string; value: string | number }]}
                disabled={!values.schemeId || isFieldDisabled}
                onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                  const selectedPlan = selectedFund?.plans
                    ?.filter((plan) => plan.isActive)
                    ?.find((p) => Number(p.id) === value);
                  setSelectedPlan(selectedPlan || null);
                  setValues({
                    ...values,
                    planId: value as string,
                    commitmentAmount: 0,
                    contributionChequeAmount: 0,
                    setupFeePercentage: 0,
                    managementFeesRate: selectedPlan?.managementFee,
                    setupFeeAmount: 0,
                    setupFeeGST: 0,
                    totalSetupFee: 0,
                    setupFeeTDS: 0,
                    netSetupFeeLessTDS: 0,
                    totalAmountPayable: 0,
                    applicationOperatingExpenses: selectedPlan?.operatingExpenses,
                    applicationSetupFee: selectedPlan?.fundSetupFee,
                    applicationAdditionalReturn: selectedPlan?.additionalReturn,
                  });
                }}
                searchFieldPlaceholder={'Search Class (Plan)'}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <AmountTextField
                name="commitmentAmount"
                label="Commitment Amount"
                placeholder="Enter Commitment Amount"
                currency={selectedPlan?.currency as string}
                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                  const commitmentAmountNumber = value.split(',').join('');
                  setValues({
                    ...values,
                    commitmentAmount: commitmentAmountNumber
                      ? Number(commitmentAmountNumber)
                      : null,
                    contributionChequeAmount: commitmentAmountNumber
                      ? (Number(commitmentAmountNumber) * Number(values.managementFeesRate)) / 100
                      : null,
                  });
                }}
                disabled={isFieldDisabled || !values.planId}
              />
              {values.commitmentAmount != 0 && (
                <Typography sx={{ fontSize: 13 }}>
                  {currencyConversion(values.commitmentAmount, selectedPlan?.currency)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'managementFeesRate'}
                label="Management Fees Rate (%)"
                placeholder="Enter Management Fee Percentage"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={true}
                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                  setValues({
                    ...values,
                    managementFeesRate: value ? Number(value) : null,
                    contributionChequeAmount: value
                      ? (Number(value) * Number(values.commitmentAmount)) / 100
                      : null,
                  });
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={'taxRate'}
              label="Tax Rate"
              placeholder="Enter Tax Rate"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={'drawDownNumber'}
              label="Drawdown Number"
              items={DrawDownMasters.map((master) => ({ key: master, value: master }))}
            />
          </Grid> */}
            <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <AmountTextField
                name="contributionChequeAmount"
                label="Initial Drawdown Amount *"
                placeholder="Enter Initial Drawdown Amount"
                currency={selectedPlan?.currency as string}
                disabled={true}
              />
              {values.contributionChequeAmount != 0 && (
                <Typography sx={{ fontSize: 13 }}>
                  {currencyConversion(values.contributionChequeAmount, selectedPlan?.currency)}
                </Typography>
              )}
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <MFTextField
                name={'setupFeePercentage'}
                label="Setup Fee Percentage *"
                type="number"
                placeholder="Enter Setup Fee Percentage"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                  const feeAmountObj = setUpFeeCalculations(
                    values.commitmentAmount || 0,
                    Number(value)
                  );
                  // const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                  setValues({
                    ...values,
                    setupFeePercentage: value ? Number(value) : null,
                    setupFeeAmount: feeAmountObj.feeAmount,
                    setupFeeGST: feeAmountObj.feeGst,
                    totalSetupFee: feeAmountObj.totalSetupFee,
                    // netSetupFeeLessTDS: netSetupFee,
                    // totalAmountPayable: values.contributionChequeAmount || values.commitmentAmount,
                  });
                }}
                trimOnBlur={false}
                disabled={isFieldDisabled || !values.planId}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <MFTextField
                name={'setupFeeAmount'}
                label="Setup Fee Amount"
                type="number"
                placeholder="Enter Setup Fee Amount"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={true}
              />
              {values.setupFeeAmount != 0 && (
                <Typography sx={{ fontSize: 13 }}>
                  {currencyConversion(values.setupFeeAmount, selectedPlan?.currency)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <MFTextField
                name={'setupFeeGST'}
                label="GST (GST On Setup Fee Amount)"
                type="number"
                placeholder="Enter GST"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={true}
              />
              {values.setupFeeGST != 0 && (
                <Typography sx={{ fontSize: 13 }}>
                  {currencyConversion(values.setupFeeGST, selectedPlan?.currency)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <MFTextField
                name={'totalSetupFee'}
                label="Total Setup Fee"
                type="number"
                placeholder="Enter Total Setup Fee"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={true}
              />
              {values.totalSetupFee != 0 && (
                <Typography sx={{ fontSize: 13 }}>
                  {currencyConversion(values.totalSetupFee, selectedPlan?.currency)}
                </Typography>
              )}
            </Grid> */}
            {/* <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <MFTextField
                name={'setupFeeTDS'}
                label="TDS"
                type="number"
                placeholder="Enter TDS"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                  const setupFeeLessTDS = (values.totalSetupFee || 0) - Number(value);
                  setValues({
                    ...values,
                    setupFeeTDS: value ? Number(value) : null,
                    netSetupFeeLessTDS:
                      values.totalSetupFee && value ? setupFeeLessTDS : values.totalSetupFee,
                    totalAmountPayable: values.contributionChequeAmount || values.commitmentAmount,
                  });
                }}
                trimOnBlur={false}
                disabled={isFieldDisabled || !values.planId}
              />
              {values.setupFeeTDS != 0 && (
                <Typography sx={{ fontSize: 13 }}>
                  {currencyConversion(values.setupFeeTDS, selectedPlan?.currency)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <MFTextField
                name={'netSetupFeeLessTDS'}
                label="Setup Fee Payable"
                type="number"
                placeholder="Enter Setup Fee Payable"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={true}
              />
              {values.netSetupFeeLessTDS != 0 && (
                <Typography sx={{ fontSize: 13 }}>
                  {currencyConversion(values.netSetupFeeLessTDS, selectedPlan?.currency)}
                </Typography>
              )}
            </Grid> */}
            {/* <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <MFTextField
                name={'totalAmountPayable'}
                label="Total Amount Payable"
                type="number"
                placeholder="Enter Total Amount Payable"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={true}
              />
              {values.totalAmountPayable != 0 && (
                <Typography sx={{ fontSize: 13 }}>
                  {currencyConversion(values.totalAmountPayable, selectedPlan?.currency)}
                </Typography>
              )}
            </Grid> */}

            {/* <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <MFTextField
                name={'operatingExpensePercentage'}
                label="Operating Expense Percentage"
                placeholder="Enter Operating Expense Percentage"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                onChange={({ target: { value } }) => {
                  setValues({
                    ...values,
                    operatingExpensePercentage: value ? Number(value) : null,
                    operatingExpense: value
                      ? (Number(value) * Number(values.commitmentAmount)) / 100
                      : null,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
              <MFTextField
                name={'operatingExpense'}
                label="Operating Expense"
                placeholder="Enter Operating Expense"
                disabled={true}
              />
              {values.operatingExpense != 0 && (
                <Typography sx={{ fontSize: 13 }}>
                  {currencyConversion(values.operatingExpense, selectedPlan?.currency)}
                </Typography>
              )}
            </Grid> */}

            {/* <Grid
            item
            xs={12}
            onClick={() => setPaymentOpen(!paymentOpen)}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              my: 2,
            }}>
            <Typography
              component="h3"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: 25,
                fontWeight: 500,
                color: 'secondary.main',
              }}>
              Payment Details
            </Typography>
            <ExpandMoreIcon sx={{ fontSize: 30, transform: paymentOpen ? 'rotate(180deg)' : '' }} />
          </Grid> */}
            {/* {paymentOpen && (
            <>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankame'}
                  label="Payment Bank Name"
                  placeholder="Enter Payment Bank Name"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankAccountNumber'}
                  label="Payment Bank Account Number"
                  placeholder="Enter Payment Bank Account Number"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
                <UseRadioGroup
                  name={'paymentBankAccontType'}
                  formLabel="Payment Bank Account Type"
                  defaultValue="current"
                  items={[
                    { label: 'Current', value: 'current' },
                    { label: 'Savings', value: 'savings' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <UseRadioGroup
                  name={'modeOfPayment'}
                  formLabel="Mode of Payment"
                  defaultValue="cheque"
                  items={[
                    { label: 'Cheque', value: 'cheque' },
                    { label: 'RTGS', value: 'rtgs' },
                    { label: 'Online Transfer', value: 'online-transfer' },
                    { label: 'Others', value: 'others' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              {values.modeOfPayment === 'cheque' && (
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Payment/Cheque Date'}
                    placeholder={'Enter Cheque Date'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    name="chequeDate"
                    disabled={isFieldDisabled}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentRefNo'}
                  label="Payment Ref No"
                  placeholder="Enter Payment Ref No"
                  disabled={isFieldDisabled}
                />
              </Grid>
            </>
          )} */}
            {values?.applicationOperatingExpenses && (
              <Grid item xs={12} pb={2}>
                <ReadOnlyView
                  label={'Operating Expenses'}
                  value={values?.applicationOperatingExpenses}
                />
              </Grid>
            )}
            {values?.applicationSetupFee && (
              <Grid item xs={12} pb={2}>
                <ReadOnlyView label={'Setup Fee'} value={values?.applicationSetupFee} />
              </Grid>
            )}
            {/* {values?.applicationAdditionalReturn && (
              <Grid item xs={12} pb={2}>
                <ReadOnlyView
                  label={'Additional Return'}
                  value={values?.applicationAdditionalReturn}
                />
              </Grid>
            )} */}
            <ProceedSaveLater
              saveLater={() => {
                setValues({
                  ...values,
                  saveType: 'save for later',
                });
              }}
              saveAndProceed={() => {
                setValues({
                  ...values,
                  saveType: 'save and proceed',
                });
              }}
              loader={loading}
              clickedButton={values.saveType}
            />
          </Grid>
        )}
      </Formik>

      <ConfirmationDialog
        message={`Are you sure you want to proceed with Group Investment Option ?`}
        open={openDialog?.investmentData.groupInvestment as boolean}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            setLoading(true);
            await submitInvestmentData(
              openDialog?.investmentData as Values,
              openDialog?.checkApplication as boolean
            );
          } catch (e) {
            setLoading(false);
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
        displayInfo={
          Number(openDialog?.investmentData.commitmentAmount) > Number(maxCommitmentAmount)
            ? 'Commitment amount does not match with provided class of units'
            : ''
        }
      />
    </>
  );
}
