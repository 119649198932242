import { Box, Button, Dialog, Grid, IconButton, LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Location } from 'history';
import {
  editDistributor,
  getDistributorAndSRMByRmid,
  getRMsList,
  updateDistributorRM,
} from '../../redux-store/actions/userManagement';
import { useDispatch } from 'react-redux';
import {
  RmWithDistributors,
  DistributorWithSubdistributor,
  DistributorListType,
  SubDistributorListType,
  newRmType,
  RmsType,
} from '../../redux-store/types/api-types';
import { DataTable } from '../DataTable';
import { Formik } from 'formik';
import { USER_ROLES } from '../../utils/constant';
import { useSnackbar } from 'notistack';
import { ConfirmationDialog, LoadingDialog } from '../commonComponents';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { styles } from '../../Styles/styles';

const headersIndividual = [
  {
    header: 'Distributor Name',
    label: 'name',
  },
  {
    header: 'Email Id',
    label: 'email',
    valueGetter: (row: DistributorListType) => row.user?.email || 'N/A',
  },
  {
    header: 'Mobile Number',
    label: 'contact',
    valueGetter: (row: DistributorListType) => row.user?.phone || 'N/A',
  },
  // {
  //   header: 'PAN',
  //   label: 'panNumber',
  //   valueGetter: (row: DistributorListType) => row.panNumber || 'N/A',
  // },
  {
    header: 'ARN Code',
    valueGetter: (row: DistributorListType) => row.arnCode || 'N/A',
  },
];

const headersDistributorRm = [
  {
    header: `Distributor's RM name`,
    valueGetter: (row: SubDistributorListType) => row.user?.firstName || 'N/A',
  },
  {
    header: 'Email Id',
    valueGetter: (row: SubDistributorListType) => row.user?.email || 'N/A',
  },
  {
    header: 'Mobile Number',
    label: 'contact',
    valueGetter: (row: DistributorListType) => row.user?.phone || 'N/A',
  },
  // {
  //   header: 'PAN',
  //   valueGetter: (row: SubDistributorListType) => row.panNumber || 'N/A',
  // },
  {
    header: `Distributor's RM code`,
    valueGetter: (row: SubDistributorListType) => row.distributors_RM_Code || 'N/A',
  },
];

function MappedSrmsForRm({ location }: { location: Location<{ rmId: number }> }): JSX.Element {
  const { rmId: idForRm } = location.state || { rmId: '' };
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [distributorsByRmid, setDistributorsByRmid] = useState<DistributorWithSubdistributor[]>();
  const [open, setOpen] = useState<{
    distributorORSrmsRowData: DistributorListType | SubDistributorListType;
  } | null>(null);
  const [selectedRM, setSelectRm] = useState<RmsType[]>([]);
  const [loadingPopup, setloadingPopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<{
    distributorORSrmsRowDataForConfirmationDialog: DistributorListType | SubDistributorListType;
    updatedRmId: number;
  } | null>(null);

  const handleClose = () => {
    setOpen(null);
  };
  const handleLoadingPopupClose = () => {
    setloadingPopup(false);
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        if (idForRm) {
          const getDetails = (await dispatch(
            getDistributorAndSRMByRmid(idForRm)
          )) as unknown as RmWithDistributors;
          setDistributorsByRmid(getDetails.distributors as DistributorWithSubdistributor[]);
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const rmUpdateHeader = [
    {
      header: '',
      renderCell: (row: DistributorListType | SubDistributorListType): JSX.Element => {
        return (
          <Typography
            variant="h5"
            //   component={RouterLink}
            //   to={{ pathname: 'srms', state: { rmId: row.id } }}
            sx={{
              color: '#61D1D6',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              cursor: 'pointer',
              pointerEvents: 'all',
            }}
            onClick={async () => {
              try {
                setloadingPopup(true);
                const res = (await dispatch(
                  getRMsList({ isActive: true })
                )) as unknown as newRmType;
                const { rms } = res || {};
                setSelectRm(rms);
                setOpen({ distributorORSrmsRowData: row });
                handleLoadingPopupClose();
              } catch (e) {
                handleLoadingPopupClose();
                console.error((e as Error).message);
              }
            }}>
            Update RM
          </Typography>
        );
      },
    },
  ];

  const initialValues: { rmId?: number } = {
    rmId: open?.distributorORSrmsRowData.rmId,
  };

  const onSubmit = (values: { rmId?: number }) => {
    setOpenConfirmationDialog({
      distributorORSrmsRowDataForConfirmationDialog: open?.distributorORSrmsRowData as
        | DistributorListType
        | SubDistributorListType,
      updatedRmId: values.rmId as number,
    });
  };

  return (
    <Box sx={{ bgcolor: 'common.white', px: 4 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          pt: { xs: 2, sm: 7 },
          pb: 2,
          flexDirection: { xs: 'column', sm: 'row' },
        }}>
        <IconButton
          sx={{
            fontSize: 20,
            ':hover': {
              textDecoration: 'underline',
              backgroundColor: '#fff',
            },
          }}
          onClick={() => history.push('relationship-managers')}>
          {/* // <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} /> */}
          Relationship Managers
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <NavigateNextIcon fontSize="medium" />
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600,
              color: '#1C2D47',
            }}>
            {"List of Mapped SRM's"}
          </Typography>
        </Box>
      </Box>
      {/* <Box sx={{ pb: { xs: 2, sm: 4 } }}>
        <Notes displayContent={"If you want to update RM then SRM's should be active"} />
      </Box> */}
      {loading ? (
        <LinearProgress />
      ) : distributorsByRmid && distributorsByRmid.length > 0 ? (
        distributorsByRmid.map((distributorsByRm, index) => {
          return (
            <React.Fragment key={index}>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'primary.main',
                  pl: { xs: 2, sm: 2 },
                  textTransform: 'capitalize',
                  pt: 2,
                  pb: 0.5,
                }}>
                {distributorsByRm.name} {'(' + distributorsByRm.type + ')'}
              </Typography>
              <Grid item xs={12} sm={12} lg={12} sx={{ pb: 2 }}>
                <DataTable
                  tableData={
                    distributorsByRm.type === 'company'
                      ? distributorsByRm.subdistributors
                      : [distributorsByRm]
                  }
                  tableHeader={
                    distributorsByRm.type === 'company'
                      ? [...headersDistributorRm, ...rmUpdateHeader]
                      : [...headersIndividual, ...rmUpdateHeader]
                  }
                  isLoading={loading}
                  renderAdditionalRow={true}
                  tableBodyCustomStyles={styles.UsersTableBodyCustomStyles}
                  tableHeaderCustomStyles={styles.UsersTableHeaderCustomStyles}
                  boxStyles={{
                    maxHeight: 'calc(100vh - 240px)',
                    minHeight: '150px',
                  }}
                />
              </Grid>
            </React.Fragment>
          );
        })
      ) : (
        <Typography
          sx={{
            textAlign: 'center',
            height: 100,
          }}>
          No data found
        </Typography>
      )}

      <Dialog
        onClose={handleClose}
        open={open !== null}
        sx={{
          '.MuiPaper-root ': {
            py: 4,
            px: 2,
            width: { xs: '90%', sm: '70%' },
          },
        }}>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              fontSize: 18,
              fontWeight: 500,
              textAlign: 'center',
            },
          }}>
          <Typography sx={{ color: 'primary.main' }}>Update RM</Typography>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit, values }) => (
              <Box component="form" noValidate onSubmit={handleSubmit}>
                <SearchableSelect
                  name="rmId"
                  label="RM Name"
                  items={selectedRM
                    .sort((rm1, rm2) =>
                      (rm1['name'] || '').toString().localeCompare((rm2['name'] || '').toString())
                    )
                    .map((item) => ({
                      key: item.name,
                      value: item.id,
                    }))}
                  searchFieldPlaceholder={'Search RM'}
                />

                <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    variant="outlined"
                    sx={{
                      fontSize: 15,
                      color: 'primary.main',
                      minWidth: 180,
                      letterSpacing: 2,
                      fontWeight: 500,
                    }}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: 15,
                      minWidth: 180,
                      letterSpacing: 2,
                      fontWeight: 400,
                    }}
                    type="submit">
                    Update
                  </Button>
                </Grid>
              </Box>
            )}
          </Formik>
        </Box>
      </Dialog>
      <ConfirmationDialog
        message={`Are you sure you want to update the RM ?`}
        open={openConfirmationDialog !== null}
        setOpen={() => setOpenConfirmationDialog(null)}
        onSave={async () => {
          try {
            handleClose();
            setOpenConfirmationDialog(null);
            setloadingPopup(true);
            if (
              openConfirmationDialog?.distributorORSrmsRowDataForConfirmationDialog.user.role ===
              USER_ROLES.SUBDISTRIBUTOR
            ) {
              await dispatch(
                updateDistributorRM(
                  openConfirmationDialog?.distributorORSrmsRowDataForConfirmationDialog.id,
                  {
                    ...openConfirmationDialog?.distributorORSrmsRowDataForConfirmationDialog,
                    rmId: openConfirmationDialog?.updatedRmId,
                  }
                )
              );
            } else if (
              openConfirmationDialog?.distributorORSrmsRowDataForConfirmationDialog.user.role ===
              USER_ROLES.DISTRIBUTOR
            ) {
              await dispatch(
                editDistributor(
                  openConfirmationDialog?.distributorORSrmsRowDataForConfirmationDialog.id,
                  {
                    ...openConfirmationDialog?.distributorORSrmsRowDataForConfirmationDialog,
                    rmId: openConfirmationDialog?.updatedRmId,
                  }
                )
              );
            }
            const getDetails = (await dispatch(
              getDistributorAndSRMByRmid(idForRm)
            )) as unknown as RmWithDistributors;
            setDistributorsByRmid(getDetails.distributors as DistributorWithSubdistributor[]);
            handleLoadingPopupClose();
            enqueueSnackbar('RM Updated Successfully', {
              variant: 'success',
              autoHideDuration: 3000,
            });
          } catch (e) {
            handleLoadingPopupClose();
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setOpenConfirmationDialog(null)}
      />
      <LoadingDialog loadingPopup={loadingPopup} onLoadingPopupClose={handleLoadingPopupClose} />
    </Box>
  );
}
export default MappedSrmsForRm;
