//should inform Backend Team if any modifications done in this file

export const phoneRegExp = /^[2-9]\d{9}$/;
export const individualPanRegex = /^[A-Z]{3}[P][A-Z][0-9]{4}[A-Z]{1}$/;
export const nonIndividualPanRegex = /^[A-Z]{3}[A-OQ-Z][A-Z][0-9]{4}[A-Z]{1}$/;
export const ifscRegex = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;
export const accountNumberRegex = /^[0-9]{9,18}$/;
export const stringRegex = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
export const addressRegex = /^[a-zA-Z0-9\s-/',&()#:.']{0,}$/;
export const emailRegex =
  /^[a-zA-Z0-9](?!.*?[^\na-zA-Z0-9]{2})[^\s@]+@[^\s@]+\.[^\s@]+[a-zA-Z0-9]$/;
export const amountRegex = /^[1-9]{1}[0-9]+$/;
export const numberRegex = /^[0-9]+$/;
export const alphaNumeric = /^[a-zA-Z0-9]{0,}$/;
export const indianPin = /^[0-9]\d{5}$/;
export const arnCodeRegex = /^[ARN|DUM]{3}[-]{1}[a-zA-Z0-9]{0,}$/;
export const pinCodeRegexOtherThanIndia = /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/;
export const cdslRegex = /^[0-9]{8}$/;
export const dpIdRegex = /^[IN|in|In|iN]{2}[0-9]{6}$/;
