import { useEffect, useState } from 'react';
import {
  AppBar,
  Button,
  CardMedia,
  Divider,
  Grid,
  List,
  ListItem,
  Skeleton,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';
import { getDetailsWithReferenceId, getOtpForInvestor } from '../../redux-store/actions/onBoarding';
import {
  GetDetailsFromRefIDResponse,
  GetOtpForInvestorResponse,
} from '../../redux-store/types/api-types';
import { Footer } from '../commonComponents';
import { currencyConversion } from '../../utils/utilityFunctions';
import { CurrencyMaster, defaultCurrency } from '../../utils/constant';
import { SubHeading } from '../investors/components';
import { KEY_INFORMATION } from './key-information';

export default function InvestmentDetails(): JSX.Element {
  const history = useHistory();
  const { params } = useRouteMatch();
  const [details, setDetails] = useState<GetDetailsFromRefIDResponse>({
    schemeName: '',
    planDescription: '',
    commitmentAmount: 0,
    contributionChequeAmount: 0,
    managementFeesRate: 0,
    currency: defaultCurrency,
    mode: '',
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonPhone: '',
    distributorName: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { referenceId } = params as unknown as { referenceId: string };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setIsLoading(true);
        const response = (await dispatch(
          getDetailsWithReferenceId(referenceId)
        )) as unknown as GetDetailsFromRefIDResponse;
        if (!isComponentAlive) return;
        setDetails(response);
        setIsLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const getDetails = (heading: string, detail: string | number): JSX.Element => {
    const localeFormat = CurrencyMaster[details.currency?.toLowerCase() || '']?.localeCode;

    return (
      <Grid item xs={12} md={6}>
        <Typography gutterBottom>{heading}</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <Typography variant="body2">
              {detail
                ? ['Commitment Amount', 'Initial Drawdown Amount'].includes(heading)
                  ? detail.toLocaleString(localeFormat)
                  : ['Management Fees Rate'].includes(heading)
                  ? `${detail}%`
                  : detail
                : '-'}
            </Typography>
            {['Commitment Amount', 'Initial Drawdown Amount'].includes(heading) && detail !== 0 && (
              <Typography variant="body1">
                {currencyConversion(detail, details.currency)}
              </Typography>
            )}
          </>
        )}
      </Grid>
    );
  };

  const verifyUser = async () => {
    try {
      const response = (await dispatch(
        getOtpForInvestor(referenceId)
      )) as unknown as GetOtpForInvestorResponse;
      history.push('verify-pan', {
        ...(response || {}),
        mobile: (response || {}).phone,
        email: (response || {}).email,
        loginType: 'investor',
        referenceId,
      });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <AppBar position="static" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box>
          <CardMedia
            component="img"
            src="/images/tvs-capital.png"
            alt="Logo"
            sx={{ width: '160px', m: 2 }}
          />
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
      <Box
        component="main"
        sx={{
          height: { xs: '', sm: 'calc(100vh - 131px)' },
          pt: { xs: '', sm: '30px' },
        }}>
        <Box
          sx={{
            bgcolor: 'common.white',
            height: { xs: '', sm: 'calc(100vh - 200px)' },
            overflowY: 'auto',
            width: 'clamp(300px, 90%, 1200px)',
            mx: 'auto',
            py: '40px',
            px: { xs: '30px', sm: '80px' },
            borderRadius: '10px',
            boxShadow: { xs: '', sm: '0px 4px 12px rgba(0, 0, 0, 0.05)' },
          }}>
          <Box sx={{ width: { xs: '100%', sm: '90%' }, mx: 'auto' }}>
            <Typography
              component="h1"
              variant="h5"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: 25,
                fontWeight: 500,
                mb: 4,
                alignSelf: 'flex-start',
              }}>
              Key Client Information
            </Typography>
            <Box
              sx={{
                boxShadow: { xs: '', sm: '0px 0px 5px rgba(0, 0, 0, 0.2)' },
                borderRadius: '10px',
                p: '20px',
                my: 2,
              }}>
              <Grid
                container
                // rowSpacing={5}
                // columnSpacing={10}
                sx={{
                  '.MuiGrid-item': {
                    px: { xs: '', sm: '70px' },
                    py: '20px',
                  },
                  '.MuiTypography-root': {
                    fontSize: 16,
                    letterSpacing: '0.05em',
                    '&.MuiTypography-body2 ': { fontWeight: 600 },
                  },
                }}>
                <SubHeading>Client Summary</SubHeading>
                {[
                  { heading: 'Fund(Scheme)', key: 'schemeName' },
                  { heading: 'Class(Plan)', key: 'planDescription' },
                  { heading: 'Commitment Amount', key: 'commitmentAmount' },
                  { heading: 'Management Fees Rate', key: 'managementFeesRate' },
                  { heading: 'Initial Drawdown Amount', key: 'contributionChequeAmount' },
                  { heading: 'Mode', key: 'mode' },
                  { heading: 'Contact Person Name', key: 'contactPersonName' },
                  {
                    heading: 'Contact Person Email',
                    key: 'contactPersonEmail',
                  },
                  {
                    heading: 'Contact Person Mobile',
                    key: 'contactPersonPhone',
                  },
                  {
                    heading: 'Operating Expenses',
                    key: 'applicationOperatingExpenses',
                  },
                  {
                    heading: 'Setup Fee',
                    key: 'applicationSetupFee',
                  },
                  {
                    heading: 'Additional Return',
                    key: 'applicationAdditionalReturn',
                  },
                ].map(({ heading, key }) => getDetails(heading, details[key]))}
              </Grid>
              {details.mode?.toLowerCase() === 'distributor' && (
                <Grid
                  container
                  sx={{
                    '.MuiGrid-item': {
                      px: { xs: '', sm: '70px' },
                      py: '20px',
                    },
                    '.MuiTypography-root': {
                      fontSize: 16,
                      letterSpacing: '0.05em',
                      '&.MuiTypography-body2 ': { fontWeight: 600 },
                    },
                  }}>
                  <SubHeading>Distributor Details</SubHeading>
                  {[
                    { heading: 'Distributor Name', key: 'distributorName' },
                    {
                      heading: 'Distribution Charges Disclosure',
                      key: '',
                      value:
                        'For the distributor mode is paid by the Investment Manager in compliance with SEBI circular no. SEBI/HO/AFD/PoD/CIR/2023/054 dated April 10, 2023.',
                    },
                  ].map(({ heading, key, value }) =>
                    getDetails(heading, key ? details[key] : value || '')
                  )}
                </Grid>
              )}
              <Grid
                container
                sx={{
                  '.MuiGrid-item': {
                    px: { xs: '', sm: '70px' },
                    py: '20px',
                  },
                  '.MuiTypography-root': {
                    fontSize: 16,
                    letterSpacing: '0.05em',
                    '&.MuiTypography-body2 ': { fontWeight: 600 },
                  },
                }}>
                <SubHeading>Key Information from Contribution Agreement</SubHeading>
                <List sx={{ listStyle: 'decimal', padding: { xs: 0, sm: '0 90px' } }}>
                  {KEY_INFORMATION.map((item, index) => {
                    return (
                      <ListItem sx={{ display: 'list-item' }} key={index}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'flex-start',
                          }}>
                          <Box sx={{ flex: '1 0 30%' }}>{item.label + ' : '}</Box>
                          <Box sx={{ flex: '1 0 70%' }}>
                            {item.value ? item.value : item.renderCell ? item.renderCell() : ''}
                          </Box>
                        </Box>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{
                  mt: 5,
                  fontSize: 14,
                  lineHeight: 1.5,
                  maxWidth: '300px',
                }}
                disabled={isLoading}
                onClick={verifyUser}>
                Verify
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
