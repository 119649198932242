//should inform Backend Team if any modifications done in this file

export const salutationsMasters: string[] = ['Mr.', 'Ms.', 'M/S.', 'Mrs.'];
export const applicantStatusMasters: { [key: string]: string } = {
  Individual: 'Individual (Resident)',
  NRI: 'Individual (NRI/FRI)',
  NRI_PIO: 'NRI (PIO)',
};
export const DLCLMasters: { [key: string]: string } = {
  cdsl: 'CDSL',
  nsdl: 'NSDL',
  none: 'None',
};
export const nationalitiesMasters: string[] = ['Indian', 'Others'];
export const occupationDetailsMasters: string[] = [
  'PVT.SECTOR SERVICE',
  'PUBLIC SECTOR',
  'GOVT.SERVICE',
  'HOUSEWIFE',
  'DEFENCE',
  'PROFESSIONAL',
  'RETIRED',
  'BUSINESS',
  'AGRICULTURE',
  'STUDENT',
  'FOREX DEALER',
  'OTHERS',
];

export const grossAnnualMasters: string[] = [
  'BELOW 1 LAC',
  '1-5 LAC',
  '5-10 LACS',
  '10-25 LACS',
  '>25 LACS - 1 CRORE',
  '> 1 CRORE',
];

export const PEPsMasters: string[] = ['I am PEP', 'I am Related to PEP', 'Not Applicable'];

export const addressTypesMasters: string[] = [
  'Resident/Business',
  'Resident',
  'Business',
  'Registered Office',
  'Unspecified',
];

export const genderMasters: string[] = ['Male', 'Female', 'Others'];

export const IdentityProofsMasters: string[] = [
  'Photo PAN Card',
  'Passport',
  "Voter's ID",
  'Driving License',
  'Aadhar Card',
];

export const AddressProofsMasters: string[] = [
  'Passport',
  "Voter's ID",
  'Ration Card',
  'Electricity Bill',
  'Landline/Telephone Bill',
  'Gas Bill',
  'Latest Bank Statement/Passbook',
  'Latest Depository Account Statement',
];

export const BankAccountProofsMasters: string[] = [
  'Bank Statement with IFSC printed on it',
  'Cancelled cheque leaf verified',
  'Letter from the bank giving account details',
  'Passbook (showing bank account details)',
];

export const DrawDownMasters: number[] = [1, 2, 3, 4];

//ENABLE_RTA_APPROVER if enabled it will add RTA Approver AS AIF Approver in flow after AMC approver and In API calls need to call RTA related APIs, If disabled AMC Approver becomes AIF approver in lable changes and In API calls need to call AMC related APIs and RTA approver will be hide
export const ENABLE_RTA_APPROVER = true;

export const filterApplicationTypes = [
  { key: 'individual', value: 'Individual (Resident)' },
  { key: 'non_individual', value: 'Non-Individual' },
  { key: 'poa', value: 'Individual (POA)' },
  { key: 'nri', value: 'Individual (NRI)' },
  { key: 'nri_poa', value: 'Individual (NRI-POA)' },
  { key: 'non_individual_poa', value: 'Non-Individual (POA)' },
];

export const ModeOfHoldingTypes = [
  { key: 'single', value: 'Single' },
  { key: 'either or survivor,joint', value: 'Joint' },
];

export const IMAGE_UPLOAD_FORMATS = ['image/jpeg', 'application/pdf', 'image/png', 'image/jpg'];

export const USER_EXCEL_UPLOAD_FORMATS = [
  'xlsx',
  'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export const INVESTOR_IMAGE_UPLOAD_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];

export const APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_applicant1: 'Sent to 1st applicant',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
};

export const SUPPORT_STATUS: { [key: string]: string } = {
  sent_to_applicant1: 'Sent to 1st applicant',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  // approved_by_fundmanager: 'Approved by investment manager',
  approved_by_trustee: 'Approved by Trustee',
  sent_to_authorised_signatories: 'Sent to authorised signatories',
  approved_by_authorised_signatories: 'Approved by authorised signatories',
};

export const POA_APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_poa_approver: 'Sent to POA approver',
  sent_to_amc_approver: ENABLE_RTA_APPROVER ? 'Sent to AMC approver' : 'Sent to AIF approver',
  sent_to_rta_approver: 'Sent to AIF approver',
  sent_to_authorised_signatories: 'Sent to authorised signatories',
  approved_by_authorised_signatories: 'Approved by authorised signatories',
  approved_by_fundmanager: 'Approved by investment manager',
  approved_by_trustee: 'Approved by trustee',
  invitationexpired: 'Invitation Expired',
  sent_for_amc_clearance: 'Sent for AMC clearance',
  signed: 'Completed',
  completed: 'Completed',
  rejected: 'Rejected',
};

export const ESIGN_STATUS: { [key: string]: string } = {
  NOT_GENERATED: 'notgenerated',
  PENDING_SIGNATURE: 'pendingsignature',
  SIGNED: 'signed',
  EXPIRED: 'expired',
};

export enum USER_ROLES {
  SUPER_ADMIN = 'superadmin',
  AMC_ADMIN = 'amcadmin',
  RM = 'rm',
  DISTRIBUTOR = 'distributor',
  INVESTOR = 'investor',
  FUND_MANAGER = 'fundmanager',
  TRUSTEE = 'trustee',
  WITNESS = 'witness',
  APPLICATION = 'application',
  SUBDISTRIBUTOR = 'subdistributor',
  POAAPPROVER = 'poaapprover',
  AMCAPPROVER = 'amcapprover',
  AUTHORISEDSIGNATORY = 'authorisedsignatory',
  RTAAPPROVER = 'rtaapprover',
}

export const ApplicationStatusBasedOnRole: { [key: string]: { [key: string]: string } } = {
  [USER_ROLES.POAAPPROVER]: {
    ...POA_APPLICATION_STATUS,
    sent_to_poa_approver: 'Pending',
  },
  [USER_ROLES.AMCAPPROVER]: {
    ...POA_APPLICATION_STATUS,
    sent_to_amc_approver: 'Pending',
    sent_for_amc_clearance: 'Pending',
  },
  [USER_ROLES.RTAAPPROVER]: {
    ...POA_APPLICATION_STATUS,
    sent_to_rta_approver: 'Pending',
  },
};

export const Relations = [
  'Mother',
  'Father',
  'Daughter',
  'Son',
  'Spouse',
  'Brother',
  'Sister',
  'Others',
];

export const NomineeRelations = [
  'MOTHER',
  'FATHER',
  'DAUGHTER',
  'SON',
  'SPOUSE',
  'BROTHER',
  'SISTER',
  'OTHERS',
];

export const bulkFundType: { [key: string]: string } = {
  download: '--- select Fund or Plan for download template ---',
  fund: 'Funds',
  plan: 'Plans',
};

export const bulkUsersType: { [key: string]: string } = {
  download: '--- select user for download template ---',
  rm: 'Relationship Managers',
  individual_distributor: 'Individual Distributors',
  company_distributor: 'Company Distributors',
  distributorRm: "Distributor's RM",
  authorisedSignatory: 'Authorised Signatory',
  poaApprover: 'POA Approver',
  trustee: 'Trustees',
  investmentManager: 'Investment Managers',
  ...(ENABLE_RTA_APPROVER ? { amcApprover: 'AMC Approvers' } : {}),
  rtaApprover: 'AIF Approvers',
  amcAdmins: 'AMC Admins',
};

export const adminSupportMaster: { [key: string]: string } = {
  unlock_user: 'Unlock User',
  check_whitelisted: 'Check Whitelisted',
  whitelist_user: 'Whitelist User',
  unwhitelist_user: 'Unwhitelist User',
  change_expired_status: 'Change Expired Status',
  change_status_to_draft: 'Change Status to draft',
};

export const IS_DOB_APPLICABLE_FOR_AMC = true;

export const REJECTED_ROLES: { [key: string]: string } = {
  poaapprover: 'POA Approver',
  amcapprover: 'AMC Approver',
  rtaapprover: 'AIF Approver',
};

export const investorTypeMasters: { [key: string]: string } = {
  corporate: 'Corporate',
  trust: 'Trust',
  partnership_firm: 'Partnership Firm/ LLP',
  huf: 'HUF',
  govt_bodies: 'Army/ Government Bodies',
  registered_society: 'Registered Society',
  fii_fpi: 'FII/FPI',
  bank: 'Bank',
  institutional_investor: 'Institutional Investor',
  unincorporated_or_body_of_individuals: 'Unincorporated association or a body of individuals',
  foreign_entity: 'Foreign Entity',
};
export const IS_PENNYDROP_APPLICABLE_FOR_AMC = true;

export const getGroupNameMaster = ['Group-A', 'Group-B', 'Group-C'];

export const NON_INDIVIDUAL_APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_amc_approver: ENABLE_RTA_APPROVER ? 'Sent to AMC approver' : 'Sent to AIF approver',
  sent_to_rta_approver: 'Sent to AIF approver',
  sent_to_authorised_signatories: 'Sent to authorised signatories',
  approved_by_authorised_signatories: 'Approved by authorised signatories',
  approved_by_fundmanager: 'Approved by investment manager',
  approved_by_trustee: 'Approved by trustee',
  invitationexpired: 'Invitation Expired',
  signed: 'Completed',
  completed: 'Completed',
  rejected: 'Rejected',
};

export const CpUboCodesMaster: { [key: string]: string } = {
  C01: '- CP of legal person-ownership',
  C02: '- CP of legal person-other means',
  C03: '- CP of legal person-senior managing official',
  C04: '- CP of legal arrangement-trust-settlor',
  C05: '- CP of legal arrangement-trust-trustee',
  C06: '- CP of legal arrangement-trust-protector',
  C07: '- CP of legal arrangement-trust-beneficiary',
  C08: '- CP of legal arrangement-trust-other',
  C09: '- CP of legal arrangement-trust-other-settlor-equivalent',
  C10: '- CP of legal arrangement-trust-other-trustee-equivalent',
  C11: '- CP of legal arrangement-trust-other-protector-equivalent',
  C12: '- CP of legal arrangement-trust-other-beneficiary-equivalent',
  C13: '- CP of legal arrangement-trust-other-other-equivalent',
  C14: '- Unknown',
};
export const controllingPersonsNationalitiesMasters: string[] = ['Indian', 'Others'];

//Application types
export enum APPLICATION_TYPE {
  INDIVIDUAL = 'individual',
  NON_INDIVIDUAL = 'non_individual',
}

export const AMC_APPROVER_CHECK_FOR_INDIVIDUAL = true; // this parameter is used to check application should be sent to approver first before investor

export const ENABLE_EMAIL_OTP = true;

export const APPLICATION_STATUS_AMCAPPROVER_individual: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_amc_approver: ENABLE_RTA_APPROVER ? 'Sent to AMC approver' : 'Sent to AIF approver',
  sent_for_amc_clearance: 'Sent for AMC clearance',
  sent_to_rta_approver: 'Sent to AIF approver',
  sent_to_applicant1: 'Sent to 1st applicant',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
};
export const IndividualApplicationStatusBasedOnRole: { [key: string]: { [key: string]: string } } =
  {
    [USER_ROLES.AMCAPPROVER]: {
      ...APPLICATION_STATUS_AMCAPPROVER_individual,
      sent_to_amc_approver: 'Pending',
      sent_for_amc_clearance: 'Pending',
    },
    [USER_ROLES.RTAAPPROVER]: {
      ...APPLICATION_STATUS_AMCAPPROVER_individual,
      sent_to_rta_approver: 'Pending',
    },
  };

export const APPLICATION_LISTING_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_applicant1: 'Sent to 1st applicant',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
  sent_to_poa_approver: 'Sent to POA approver',
  sent_to_amc_approver: ENABLE_RTA_APPROVER ? 'Sent to AMC approver' : 'Sent to AIF approver',
  sent_for_amc_clearance: 'Sent to AMC clearance',
  //adding extra space for diffrentiate sent_to_rta_approver and sent_to_amc_approver when ENABLE_RTA_APPROVER is false
  sent_to_rta_approver: 'Sent to AIF approver ',
  sent_to_authorised_signatories: 'Sent to authorised signatories',
  approved_by_authorised_signatories: 'Approved by authorised signatories',
};

export enum APPLICATION_TYPE_FOR_DOCUMENTS {
  INDIVIDUAL = 'individual',
  NON_INDIVIDUAL = 'non_individual',
  INDIVIDUAL_POA = 'individual_poa',
  NRI = 'nri',
  NRI_POA = 'nri_poa',
  NOMINEE_DOC = 'nominee',
  NOMINEE_GUARDIAN = 'nomineeGuardian',
  NRI_PIO = 'nri_pio',
  NRI_POA_PIO = 'nri_poa_pio',
}
export const BankAccountTypeMaster: { [key: string]: string } = {
  Current: 'CURRENT',
  Savings: 'SAVING',
};
export const NRIBankAccountTypeMaster: { [key: string]: string } = {
  NRE: 'NRE',
  NRO: 'NRO',
};
export const DistributorTypesForUserManageMent: { [key: string]: string } = {
  Individual: 'individual',
  Company: 'company',
};

export const ENABLE_KRA_DOCUMENT_FETCH = false;

export const SALT = 'F2lNZWFxUH';
export const SALT2 = 'WTVPJel6dTGVocF';

export const ONBOARDING_OPTIONS: { [key: string]: string } = {
  otp: 'OTP',
  email_consent: 'Email Consent',
};

export const EmailConsentStatusMaster: { [key: string]: string } = {
  pending: 'Pending',
  rejected: 'Rejected',
  approved: 'Approved',
  revoked: 'Revoked',
};

export const ENABLE_Email_Consent = true;

export const BloodRelations = ['Father', 'Mother', 'Son', 'Daughter', 'Spouse'];

export const UboTypeMaster: { [key: string]: string } = {
  applicablePercentage: 'Applicable',
  notApplicable: 'Not Applicable',
  indeterminateTrust: 'Indeterminate Trust',
};

export const CurrencyMaster: { [key: string]: { currency: string; localeCode: string } } = {
  inr: { currency: 'INR', localeCode: 'en-IN' },
  usd: { currency: 'USD', localeCode: 'en-US' },
};

export const YesNoMaster: { [key: string]: string } = {
  yes: 'Yes',
  no: 'No',
};

export const defaultCurrency = 'inr';
export const CvlUrlMaster: { [key: string]: string } = {
  UAT: 'https://krapancheck.cvlindia.com',
  PROD: 'https://pancheck.www.kracvl.com',
};

export const COMMENT_ROLES: { [key: string]: string } = {
  rm: 'RM',
  distributor: 'Distributor',
  subdistributor: 'Sub Distributor',
  amcapprover: 'AMC Approver',
  poaapprover: 'POA Approver',
  rtaapprover: 'AIF Approver',
};
export const CVLVerificationEnabled = true;

export const IS_STAMP_PAPER_SERIES_MASTER: { [key: string]: string } = {
  true: 'Yes',
  false: 'No',
};

export const YES_NO_BOOLEAN_MAPPING: { [key: string]: string } = {
  true: 'Yes',
  false: 'No',
};

export const dematFieldValidationDate = '2023-09-25'; //Applicable date for validating demat fields as per regex in KYC details

export const noLimitValidationAmount = '99999999999';

export const YES_NO_MASTER: { [key: string]: string } = {
  yes: 'Yes',
  no: 'No',
};

export const RTAApproverSendBackRoles: { [key: string]: string } = {
  maker: 'Maker',
  amcapprover: 'AMC Approver',
};

export const AllApplicationStatusAsPerUserInterface: { [key: string]: string } = {
  ...APPLICATION_LISTING_STATUS,
  draft_send_back: 'Draft(Send Back)',
};

export const onboardingTypeMasters: { [key: string]: string } = {
  normal: 'Normal',
  minor: 'Minor',
};

export const ckycNoFieldValidationDate = '2024-02-17'; //Applicable date for validating CKYC No fields

export const POA_Authorized_signatory_can_Esign_Enable = true;

export const POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE = true;

export const poaValidationDate = '2024-03-07'; //Applicable date for validating POA Authorized signatories in application
