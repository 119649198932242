import { Box, LinearProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { poaApprover, AddPoaApproverRequestBody } from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import { CountryCodesDropDown } from '../commonComponents';
import {
  addPOAAppprover,
  updatePOAApprover,
  getPoaApproverById,
} from '../../redux-store/actions/userManagement';
import { AuthorisedSignatoryAndPOASchema } from '../../utils/schema';
import { Location } from 'history';
import { CommonFormForCompanyDistributors } from './addAuthorisedSignatory';
import { useEffect, useState } from 'react';
import { BackButtonAndHeader } from './userBackButton';
import { styles } from '../../Styles/styles';

export default function AddPOAApprover({
  location,
}: {
  location: Location<{ poaApproverId: number; distributorId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { poaApproverId, distributorId: ID } = location.state || {};
  const [formikValues, setFormikValues] = useState<AddPoaApproverRequestBody | null>();

  const initialValues: AddPoaApproverRequestBody = {
    name: '',
    email: '',
    countryCode: '+91',
    countryNameAndCode: 'India: +91',
    phone: '',
    panNumber: '',
    pincode: '',
    buildingNo: '',
    streetName: '',
    city: '',
    state: '',
    country: '',
    distributorId: ID,
    amcId: '',
    isActive: true,
  };
  const [poaApproverDetails, setPoaApproverDetailsDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        if (poaApproverId) {
          const getPoaApproverDetails = (await dispatch(
            getPoaApproverById(poaApproverId)
          )) as unknown as poaApprover;
          const {
            panNumber,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            distributorId,
            amcId,
            id,
            isActive,
          } = getPoaApproverDetails;
          const {
            firstName: name,
            phone,
            email,
            countryCode,
            countryNameAndCode,
          } = getPoaApproverDetails.user;
          setPoaApproverDetailsDetails({
            ...poaApproverDetails,
            name,
            panNumber,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            distributorId,
            amcId,
            id,
            phone,
            email,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  // useEffect(() => {
  //   let isComponentActive = true;
  //   (async function () {
  //     try {
  //       if (!formikValues) return;
  //       if (poaApprover) {
  //         await dispatch(updatePOAApprover(id, formikValues));
  //       } else {
  //         await dispatch(addPOAAppprover({ ...formikValues, distributorId: ID }));
  //       }
  //       if (!isComponentActive) return;
  //       history.push('poa-approver', { distributorId: ID });
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  //   return () => {
  //     isComponentActive = false;
  //   };
  // }, [formikValues]);

  const onSubmit = async (values: AddPoaApproverRequestBody) => {
    try {
      if (poaApproverId) {
        await dispatch(
          updatePOAApprover(poaApproverId, {
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        );
        history.push('poa-approver-details', {
          poaApproverId,
          distributorId: values.distributorId.toString(),
        });
      } else {
        await dispatch(
          addPOAAppprover({
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
            distributorId: ID,
          })
        );
        history.push('poa-approver', { distributorId: values.distributorId.toString() });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  return (
    <>
      <Formik
        initialValues={poaApproverDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={AuthorisedSignatoryAndPOASchema}>
        {({ handleSubmit, values }) => (
          <Box sx={styles.UsersFormikBox} component="form" noValidate onSubmit={handleSubmit}>
            <BackButtonAndHeader
              User={poaApproverId ? 'POA Approver' : 'Onboarding POA Approver'}
              onClickBack={() =>
                poaApproverId
                  ? history.push('poa-approver-details', {
                      poaApproverId: values.id,
                      distributorId: values.distributorId,
                    })
                  : history.push('poa-approver', { distributorId: values.distributorId })
              }
            />
            {loading ? <LinearProgress /> : <CommonFormForCompanyDistributors values={values} />}
          </Box>
        )}
      </Formik>
    </>
  );
}
