import { useState, useEffect } from 'react';
import { Box, Grid, LinearProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { Formik } from 'formik';
import { AddFundManagerRequestBody, FundManager } from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import {
  addFundManager,
  getFundManagersById,
  getIMAccess,
  IMAccess,
  UpdateFundManager,
} from '../../redux-store/actions/userManagement';
import { addFundManagerSchema } from '../../utils/schema';
import { Location } from 'history';
import { CountryCodesDropDown } from '../commonComponents';
import { styles } from '../../Styles/styles';
import { BackButtonAndHeader } from './userBackButton';
import { AddressFieldEditable } from './AddressFields';

const initialValues: AddFundManagerRequestBody = {
  name: '',
  email: '',
  countryNameAndCode: 'India: +91',
  countryCode: '+91',
  phone: '',
  panNumber: '',
  pincode: '',
  buildingNo: '',
  streetName: '',
  city: '',
  state: '',
  country: '',
  leegalityName: '',
  isActive: true,
};

export default function AddFundManager({
  location,
}: {
  location: Location<{ fundManagerId: number }>;
}): JSX.Element {
  const { fundManagerId } = location.state || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const [formikValues, setFormikValues] = useState<AddFundManagerRequestBody | null>();

  // useEffect(() => {
  //   let isComponentActive = true;
  //   (async function () {
  //     try {
  //       if (!formikValues) return;
  //       if (!fundManager) {
  //         await dispatch(addFundManager(formikValues));
  //       } else {
  //         await dispatch(UpdateFundManager(id, formikValues));
  //       }

  //       if (!isComponentActive) return;
  //       history.push('invest-managers');
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  //   return () => {
  //     isComponentActive = false;
  //   };
  // }, [formikValues]);

  const [fundManagerDetails, setFundManagerDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const { fundManagerAccess } = (await dispatch(getIMAccess())) as unknown as IMAccess;
        if (fundManagerId) {
          const getFundManagerDetails = (await dispatch(
            getFundManagersById(fundManagerId)
          )) as unknown as FundManager;
          const {
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            panNumber,
            id,
            leegalityName,
            isActive,
          } = getFundManagerDetails;
          const {
            firstName: name,
            phone,
            email,
            countryCode,
            countryNameAndCode,
          } = getFundManagerDetails.user;
          setFundManagerDetails({
            ...fundManagerDetails,
            name,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            panNumber,
            id,
            phone,
            email,
            leegalityName,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
          });
        }
        if (!isComponentAlive) return;
        setAccessForEditAdd(fundManagerAccess);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: AddFundManagerRequestBody) => {
    try {
      if (!fundManagerId) {
        await dispatch(
          addFundManager({
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        );
        history.push('invest-managers');
      } else {
        await dispatch(
          UpdateFundManager(fundManagerId, {
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        );
        history.push('Invest-manager-details', { fundManagerId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  return (
    <>
      <Formik
        initialValues={fundManagerDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={addFundManagerSchema}>
        {({ handleSubmit, values }) => (
          <Box sx={styles.UsersFormikBox} component="form" noValidate onSubmit={handleSubmit}>
            <BackButtonAndHeader
              User={fundManagerId ? 'Investment Managers' : 'Onboarding Investment Managers'}
              onClickBack={() =>
                fundManagerId
                  ? history.push('Invest-manager-details', { fundManagerId: values.id })
                  : history.push('invest-managers')
              }
            />
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="name"
                      label="Name (Individual) *"
                      placeholder="Enter Name"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="leegalityName"
                      label="Leegality Name *"
                      placeholder="Enter Leegality Name"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="email"
                      label="Email Id *"
                      placeholder="Enter Email Id"
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="phone"
                      label="Mobile Number *"
                      placeholder="Enter Mobile Number"
                      // startAdornment={
                      //   <CountryCodesDropDown
                      //     name={`countryNameAndCode`}
                      //     value={values.countryNameAndCode}
                      //   />
                      // }
                      disabled={!accessForEditAdd}
                    />
                  </Grid>
                  <AddressFieldEditable accessForEditAdd={accessForEditAdd} />
                </Grid>
                {accessForEditAdd && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
