import { Box, Typography } from '@mui/material';

export const KEY_INFORMATION: {
  label: string;
  value?: string;
  renderCell?: () => React.ReactNode;
}[] = [
  { label: 'Initial Closing', value: '1 year from 16- Jan- 2024' },
  { label: 'Final Closing', value: '18 months from initial closing' },
  { label: 'Term of the Fund', value: '12 years from initial closing' },
  { label: 'Investment Period', value: '6 years from initial closing' },
  {
    label: 'Contact information',
    renderCell: (): React.ReactNode => {
      return (
        <Box component={'span'}>
          <Typography component={'span'}>
            Email id{' '}
            {
              <Typography component={'span'} sx={{ fontWeight: 'bold' }}>
                tsgf4@tvscapital.in
              </Typography>
            }
          </Typography>
          <Typography>
            Website{' '}
            {
              <Typography component={'span'} sx={{ fontWeight: 'bold' }}>
                https://www.tvscapital.in
              </Typography>
            }
          </Typography>
        </Box>
      );
    },
  },
  {
    label: 'Drawdown Due Date',
    renderCell: (): React.ReactNode => {
      return (
        <Typography component={'span'}>
          21 days from the date of drawdown notice. Delayed payment will attract default interest –
          Refer{' '}
          <Typography component={'span'} sx={{ fontWeight: 'bold' }}>
            Clause 12 of the Private Placement Memorandum
          </Typography>
        </Typography>
      );
    },
  },
  { label: 'LPAC approval items', value: 'Take list from PPM' },
  { label: 'SOA Frequency', value: 'Monthly' },
  { label: 'NAV Frequency', value: 'Quarterly' },
  {
    label: 'Investor Portal',
    renderCell: (): React.ReactNode => {
      return (
        <Typography component={'span'} sx={{ fontWeight: 'bold' }}>
          https://www.tvscapital.in
        </Typography>
      );
    },
  },
  {
    label: 'Taxation',
    value:
      'Withholding taxes of 10% applicable with every distribution. Advance tax intimation will be provided by 10th of the last month of relevant quarter. Form 64C will be provided by June 30th of every year.',
  },
];
